import React from 'react';
import Layout from '../components/dfa-theme/layout';
import ContentBlock from '../components/content-block';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { Link } from "gatsby";

import { CONSTANTS } from '../utils/constants';
import '../scss/pages/privacy.scss';

const Privacy = () => {
	const metaTags = {
		name: 'Privacy',
		description: 'Deerfield Privacy',
		keywords: 'Deerfield, Privacy',
	};
	return (
		<Layout meta={metaTags}>
			<div className='privacy-hero-container'>
				<ContentBlock
					outerBgColor={CONSTANTS.WHITE}
					className='solutions block smallHero'>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col
								xs={10}
								md={8}
								className='careers-content title-block'>
								<h1 className='dark'>Privacy policy</h1>
								<p>
									Deerfield Agency has always been committed
									to maintaining the accuracy,
									confidentiality, and security of your
									personal and corporate information. We have
									established privacy principles to govern our
									use of customer information.
								</p>
								<p>
									Deerfield Agency does not sell, share, or
									distribute customer information to any
									third-party organization.
								</p>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>
				<ContentBlock outerBgColor={CONSTANTS.WHITE} className='block'>
					<Grid>
						<Row>
							<Col xs={1} md={2}></Col>
							<Col xs={10} md={8} className='careers-content careers-body'>
								<p>This Privacy Policy was last updated in August 2023.</p>
								<p>Deerfield Agency collects information from the products and services that we provide to our business customers (“<strong>Customers</strong>”), including authorized users of web-based software applications (“<strong>Applications</strong>”), to the extent we direct the purposes and means of processing of information collected from such authorized users (“<strong>App Data</strong>”), as well as users of our website, <Link to="https://www.deerfieldagency.com/">DeerfieldAgency.com</Link>, and any other websites and online services, other than the Applications, that we operate that include a link to this Privacy Policy (collectively, the “<strong>Websites</strong>”). </p>
								<p>The Applications may cause us to receive, collect, use, disclose, or process personal information on behalf of and at the direction of those Customers that are not collected through the Websites and do not constitute App Data. Instead, we process that personal information at the direction of and according to our Customer service agreements.</p>
								<p>When Customers electronically submit data for hosting and processing purposes (“<strong>Customer Data</strong>”), we will not review, share, distribute, or reference any such data except as outlined in the service agreements between the parties or as required by law. We may access Customer Data only to provide our services, address technical issues, or at the Customer’s request for support matters.</p>
								<p>We may transfer personal data to partners that help us provide our services, and such transfers are covered by our Customer and partner agreements. </p>
								<p className='header-orange'>Information We Collect</p>
								<p className='tight'>
									When you use or access the Websites or use the Applications as an authorized user of one of our Customers, we may collect personal information directly from you, such as the following:
									<ul>
										<li>Contact information, such as your name, email address, or phone number</li>
										<li>Business information, such as your job title and the name of your employer or organization</li>
										<li>Registration and account information, including your username, password, company registration code, and security code</li>
										<li>Any other information you submit when you contact us through the Websites or Applications, including any information you provide in a submission through our request form</li>
									</ul>
								</p>
								<p className='header-orange'>
									Cookies
								</p>
								<p className='tight'>
									<ul>
										<li className='cookies-list'>Cookies are small text files that are placed on your device (eg, computer, tablet, smartphone, etc) when you visit our Websites, and we collect information automatically through the use of cookies on our Websites</li>
										<li>By using our Websites, you consent to the use of cookies described herein</li>
										<li>When you visit the Websites and the promotional website for <a href="https://asset2it.com/" target="_blank" rel="noreferrer">Asset2it.com</a>, we may collect the following types of information through the use of cookies and tracking technologies:</li>
										<ul>
											<li>Usage Metrics: We collect data about your interaction with our Websites, such as the number of visits, pages viewed, and time spent on each page, which allows us to optimize content and design</li>
											<li>Video Metrics: For videos hosted on our Websites, we may track the percentage of the video viewed to understand user engagement</li>
											<li>Downloads and Prints: We may track the number of downloads and prints of materials available on our Websites</li>
											<li>Unique Landing Pages: While <a href="https://asset2it.com/" target="_blank" rel="noreferrer">Asset2it.com</a> does not use cookies for tracking, it does track unique landing pages sent to healthcare professionals to measure the effectiveness of promotional efforts and performance analysis</li>
											<li>Improving User Experience: Cookies help us remember your preferences and provide you with a personalized experience on our Websites</li>
										</ul>
										<li>We may use third-party cookies and tracking technologies on our Websites. These cookies are placed by third-party service providers to assist us in achieving the purposes mentioned above. Third-party cookies are subject to the respective privacy policies of these providers</li>
										<li>If you wish to opt out of cookie collection, you can adjust your browser settings to reject cookies or notify you when cookies are being used. Please note that disabling cookies may impact the functionality and user experience of our Websites</li>
									</ul>
								</p>
								<p className='header-orange'>Uses and Purposes for Processing</p>
								<p className='tight'>
									We use, disclose, transmit, transfer, store, and otherwise process your information when we have an appropriate legal basis. We may process the information we collect from you for our Customers’ and our legitimate interests, consistent with your rights and appropriate to the context, including for:
									<ul>
										<li>Providing, developing, maintaining, personalizing, protecting, and improving the Websites, Applications, and service offerings to you and others, including our Customers</li>
										<li>Operating, evaluating, debugging, identifying, and repairing errors, effectuating similar functional enhancements, and improving our Websites, Applications, or other services</li>
										<li>Understanding how you and our Customers use our Websites, Applications, and other services, performing analytics, analyzing, and reporting on usage and performance of the Websites, Applications, and marketing materials, and determining what features and services may interest you and our Customers</li>
										<li>Communicating with you and others, including responding to your requests and providing promotional information</li>
										<li>Offering, marketing, or advertising products, programs, and services from us, our Customers, business partners, and select third parties to you, including through targeted advertising</li>
										<li>Recruitment and hiring purposes, including evaluating and processing your employment application</li>
										<li>Storing information about your preferences, recognizing you when you use the Websites and Applications, and customizing your experience</li>
										<li>Synchronizing users across devices, business partners, Customers, and select third parties</li>
										<li>Creating aggregate or de-identified data</li>
										<li>Legal and safety purposes, such as maintaining the safety, security, and integrity of our Websites and Applications, other technology assets, services, and our organization; preserving or enforcing our legal rights and property; protecting our users, our employees, and others; and complying with industry standards and applicable law</li>
										<li>Protecting against malicious, deceptive, fraudulent, or illegal activity and participating in any prosecution or enforcement of laws or agreements meant to prevent or punish such activity</li>
										<li>To provide services that are subject to contractual terms you have accepted, such as our licenses, service agreements, and terms of use</li>
										<li>Such other purposes as you may authorize</li>
									</ul>
								</p>
								<p className='header-orange'>
									Information Security
								</p>
								<p className='tight'>
									We maintain a reasonable degree of physical, electronic, and managerial procedures to protect the information collected from the Applications and Websites. This includes maintaining computer equipment, networks, programs, and documentation to a common standard and restricting access to equipment and information to appropriate staff.
								</p>
								<p className='header-orange'>
									Storage and Retention
								</p>
								<p className='tight'>
									We store personal information for as long as we reasonably need it to fulfill the purposes for which it was collected, including the provision of the Websites and Applications, and to comply with the law, resolve disputes, and enforce our agreements, as applicable. Some information may be deleted automatically based on specific schedules.
								</p>
								<p className='header-orange'>Changes to This Policy</p>
								<p className='tight'>
									We may update this Policy to reflect changes in our privacy practices at any time and without prior notice to you. When we do so, we will update the Policy’s Effective Date. We encourage you to periodically review this Policy for the latest information on our privacy practices.
								</p>
								<p className='header-orange'>
									Contacting Us
								</p>
								<p className='tight'>
									If you have any questions or comments about this Policy or our privacy practices, or would like to ask for access to, amendment to, or deletion of your personal information, please contact us by email at <a href="mailto:Support@DeerfieldAgency.com">Support@DeerfieldAgency.com</a> or by mail at 555 E North Lane, Building D, Suite 5020, Conshohocken, Pennsylvania 19428.
								</p>
							</Col>
							<Col xs={1} md={2}></Col>
						</Row>
					</Grid>
				</ContentBlock>
			</div>
		</Layout>
	);
};
export default Privacy;
